//
// progress.scss
// Extended from Bootstrap
//

//
// Bootstrap Overrides =====================================
//

// Rounds the progress bar, even for "multiple bar" progress bars
.progress-bar:first-child {
  border-top-left-radius: var(--#{$prefix}progress-border-radius);
  border-bottom-left-radius: var(--#{$prefix}progress-border-radius);
}

.progress-bar:last-child {
  border-top-right-radius: var(--#{$prefix}progress-border-radius);
  border-bottom-right-radius: var(--#{$prefix}progress-border-radius);
}


//
// Theme ===================================
//

.progress-sm {
  --#{$prefix}progress-height: #{$progress-height-sm};
}


//
// Dark mode ===================================
//

@include color-mode(dark) {
  .progress {
    --#{$prefix}progress-bg: #{$progress-bg-dark};
  }
}