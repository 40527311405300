//
// navbar.scss
// Extended from Bootstrap
//

//
// Bootstrap Overrides =======================
//

.navbar {
  --#{$prefix}navbar-bg: #{$navbar-light-bg};
  --#{$prefix}navbar-border-color: #{$navbar-light-border-color};
  --#{$prefix}navbar-heading-color: #{$navbar-light-heading-color};
  --#{$prefix}navbar-divider-color: #{$navbar-light-divider-color};
  --#{$prefix}navbar-brand-filter: #{$navbar-light-brand-filter};
  background-color: var(--#{$prefix}navbar-bg);
  border-color: var(--#{$prefix}navbar-border-color);
  border-width: 0 0 var(--#{$prefix}border-width) 0;
  border-style: solid;
}

.navbar > [class*='container'] {
  padding-left: 0;
  padding-right: 0;
}

.navbar .navbar-heading {
  color:var(--#{$prefix}navbar-heading-color);
}

.navbar .navbar-divider {
  color: var(--#{$prefix}navbar-divider-color);
}

.navbar .navbar-user {
  border-top-color: var(--#{$prefix}navbar-divider-color);
}

.navbar .navbar-user-link {
  color: var(--#{$prefix}navbar-color);

  &:hover,
  &:focus {
    color: var(--#{$prefix}navbar-hover-color);
  }
}

.navbar .navbar-brand {
  filter: var(--#{$prefix}navbar-brand-filter);
}

.navbar .navbar-collapse::before {
  border-top-color: var(--#{$prefix}navbar-divider-color);
}

.navbar-nav .nav-link:not(.active) {
  color: var(--#{$prefix}navbar-color);

  &:hover, &:focus {
    color: var(--#{$prefix}navbar-hover-color);
  }
}

// Navbar brand

.navbar-brand {
  margin-right: 0;
}

.navbar-brand-img,
.navbar-brand > img {
  max-width: 100%;
  max-height: 1.5rem;
}

// Navbar collapse

.navbar-collapse {
  width: calc(100% + #{var(--#{$prefix}navbar-padding-x)} * 2);
  margin-left: calc(var(--#{$prefix}navbar-padding-x) * -1);
  margin-right: calc(var(--#{$prefix}navbar-padding-x) * -1);
  padding-left: var(--#{$prefix}navbar-padding-x);
  padding-right: var(--#{$prefix}navbar-padding-x);

  // Spacer to replace margin / padding as those
  // cause .navbar-collapse to "jump" when collapsing
  &:before {
    content: '';
    display: block;
    border-top-width: var(--#{$prefix}border-width);
    border-top-style: solid;
    margin: var(--#{$prefix}navbar-padding-y) calc(var(--#{$prefix}navbar-padding-x) * -1);
  }
}

// Navbar navigaton

.navbar-nav {
  margin-left: calc(var(--#{$prefix}navbar-padding-x) * -1);
  margin-right: calc(var(--#{$prefix}navbar-padding-x) * -1);
}

.navbar-nav .nav-link {
  padding-left: var(--#{$prefix}navbar-padding-x);
  padding-right: var(--#{$prefix}navbar-padding-x);

  &.active {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: var(--#{$prefix}nav-link-padding-y);
      bottom: var(--#{$prefix}nav-link-padding-y);
      border-left: 2px solid var(--#{$prefix}primary);
    }
  }
}

// Navbar icons

.navbar-nav .nav-link > .fe {
  min-width: $navbar-icon-min-width;
  padding-bottom: 0.125em;
  font-size: $font-size-lg;
  line-height: ($font-size-base * $line-height-base);
}

// Navbar dropdowns

.navbar-nav .dropdown-menu {
  border: none;
}

.navbar-nav .dropdown-menu .dropdown-menu {
  margin-left: calc(var(--#{$prefix}dropdown-item-padding-x) * 0.5);
}

.navbar-expand {
  @each $breakpoint, $value in $grid-breakpoints {
    $prev: breakpoint-prev($breakpoint, $grid-breakpoints);

    &-#{$breakpoint} {
      @include media-breakpoint-down(#{$prev}) {
        .navbar-nav .dropdown-menu {
          background-color: transparent;
        }
      }
    }
  }
}

// Expanded
//
// Expanded navbar specific styles

.navbar-expand {
  @each $breakpoint, $value in $grid-breakpoints {
    &-#{$breakpoint} {
      @include media-breakpoint-up(#{$breakpoint}) {
        padding-left: 0;
        padding-right: 0;

        > [class*='container'] {
          padding-left: 0;
          padding-right: 0;
        }

        .navbar-collapse {
          flex-grow: inherit;
          flex-basis: auto;
          width: auto;

          &:before {
            display: none;
          }
        }

        .navbar-nav {
          margin-left: 0;
          margin-right: 0;
        }

        .navbar-nav .nav-link {
          // Increase $nav-link-padding-y value to align the underline
          // with the bottom of the navbar
          padding: 0.625rem var(--#{$prefix}navbar-nav-link-padding-x);

          &.active {
            position: relative;

            &:before {
              top: auto;
              bottom: calc(#{var(--#{$prefix}navbar-padding-y)} * -1 - #{var(--#{$prefix}border-width)});
              right: var(--#{$prefix}navbar-nav-link-padding-x);
              left: var(--#{$prefix}navbar-nav-link-padding-x);
              border-left: 0;
              border-bottom: 1px solid var(--#{$prefix}primary);
            }
          }
        }

        .navbar-nav .dropdown-menu {
          border: var(--#{$prefix}dropdown-border-width) solid var(--#{$prefix}dropdown-border-color);
        }

        .navbar-nav .dropdown-menu .dropdown-menu {
          margin-left: 0;
        }
      }
    }
  }
}

//
// Theme ===================================
//

// Navbar fixed
//
// Adds special zindex to custom fixed-start and fixed-end classes

.navbar[class*='fixed-'] {
  z-index: $zindex-fixed;
}

// Navbar overflow
//
// Allows the navbar navigation overflow its parent container
// horizontally

.navbar-overflow {
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  margin-bottom: calc(var(--#{$prefix}navbar-padding-y) * -1);
  overflow-x: auto;

  // Hide scrollbar
  &::-webkit-scrollbar {
    display: none;
  }
}

.navbar-overflow .navbar-nav {
  flex-direction: row;
  flex-wrap: nowrap;
  margin-left: calc(var(--#{$prefix}navbar-nav-link-padding-x) * -1);
  margin-right: calc(var(--#{$prefix}navbar-nav-link-padding-x) * -1);
}

.navbar-overflow .navbar-nav .nav-link {
  padding-bottom: calc(var(--#{$prefix}nav-link-padding-y) + var(--#{$prefix}navbar-padding-y));

  &.active {
    &:before {
      bottom: 0;
    }
  }
}

// Navbar breadcrumb
//
// Applies special paddings to the navbar breadcrumb

.navbar-breadcrumb {
  padding: $navbar-breadcrumb-padding-y $navbar-breadcrumb-padding-x;
}

// Navbar user
//
// Navbar user notification area

.navbar-user {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.navbar-user .dropdown-toggle {
  // Remove the default dropdown arrow
  &:after {
    display: none;
  }
}

// Navbar link icon

.navbar-nav .nav-link > .fe {
  display: inline-block;
  min-width: $navbar-icon-min-width;
}

// Vertical
//
// Creates a vertically aligned version of the navbar

.navbar-vertical {
  --#{$prefix}navbar-vertical-width: #{$navbar-vertical-width};
  --#{$prefix}navbar-vertical-padding-x: #{$navbar-vertical-padding-x};

  .navbar-nav .nav-link {
    display: flex;
    align-items: center;
  }

  .navbar-nav .nav-link[data-bs-toggle='collapse'] {
    &:after {
      display: block;
      content: '\e92e';
      font-family: 'Feather';
      margin-left: auto;
      transition: transform 0.2s;
    }

    // Expanded
    &[aria-expanded='true'] {
      &:after {
        transform: rotate(-180deg);
      }
    }
  }

  .navbar-nav .nav .nav-link {
    padding-left: calc(var(--#{$prefix}navbar-padding-x) + #{$navbar-icon-min-width});
  }

  .navbar-nav .nav .nav .nav-link {
    padding-left: calc(var(--#{$prefix}navbar-padding-x) * 1.5 + #{$navbar-icon-min-width});
  }

  .navbar-nav .nav .nav .nav .nav-link {
    padding-left: calc(var(--#{$prefix}navbar-padding-x) * 2 + #{$navbar-icon-min-width});
  }

  .navbar-heading {
    padding-top: $nav-link-padding-y;
    padding-bottom: $nav-link-padding-y;
    font-size: $font-size-xs;
    text-transform: uppercase;
    letter-spacing: 0.04em;
  }

  // Expanded
  //
  // Expanded navbar specific styles

  &.navbar-expand {
    @each $breakpoint, $value in $grid-breakpoints {
      &-#{$breakpoint} {
        @include media-breakpoint-up(#{$breakpoint}) {
          display: block;
          position: fixed;
          top: 0;
          bottom: 0;
          width: 100%;
          max-width: var(--#{$prefix}navbar-vertical-width);
          padding-left: var(--#{$prefix}navbar-vertical-padding-x);
          padding-right: var(--#{$prefix}navbar-vertical-padding-x);
          overflow-y: auto;

          > [class*='container'] {
            flex-direction: column;
            align-items: stretch;
            min-height: 100%;
            padding-left: 0;
            padding-right: 0;

            // Target IE 10 & 11
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
              min-height: none;
              height: 100%;
            }
          }

          &.fixed-start {
            left: 0;
            border-width: 0 1px 0 0;
          }

          &.fixed-end {
            right: 0;
            border-width: 0 0 0 1px;
          }

          .navbar-collapse {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: stretch;
            width: auto;
            min-width: 100%;
            margin-left: calc(var(--#{$prefix}navbar-vertical-padding-x) * -1);
            margin-right: calc(var(--#{$prefix}navbar-vertical-padding-x) * -1);
            padding-left: var(--#{$prefix}navbar-vertical-padding-x);
            padding-right: var(--#{$prefix}navbar-vertical-padding-x);

            > * {
              min-width: 100%;
            }
          }

          .navbar-nav {
            flex-direction: column;
            margin-left: calc(var(--#{$prefix}navbar-vertical-padding-x) * -1);
            margin-right: calc(var(--#{$prefix}navbar-vertical-padding-x) * -1);
          }

          .navbar-nav .nav-link {
            padding: var(--#{$prefix}nav-link-padding-y) var(--#{$prefix}navbar-vertical-padding-x);

            &.active {
              &:before {
                top: var(--#{$prefix}nav-link-padding-y);
                bottom: var(--#{$prefix}nav-link-padding-y);
                left: 0;
                right: auto;
                border-left: 2px solid var(--#{$prefix}primary);
                border-bottom: 0;
              }
            }
          }

          .navbar-nav .nav .nav-link {
            padding-left: calc(var(--#{$prefix}navbar-vertical-padding-x) + #{$navbar-icon-min-width});
          }

          .navbar-nav .nav .nav .nav-link {
            padding-left: calc(var(--#{$prefix}navbar-vertical-padding-x) * 1.5 + #{$navbar-icon-min-width});
          }

          .navbar-nav .nav .nav .nav .nav-link {
            padding-left: calc(var(--#{$prefix}navbar-vertical-padding-x) * 2 + #{$navbar-icon-min-width});
          }

          .navbar-brand {
            display: block;
            text-align: center;
            padding-top: calc(2rem - var(--#{$prefix}navbar-padding-y));
            padding-bottom: calc(2rem - var(--#{$prefix}navbar-padding-y));
          }

          .navbar-brand-img {
            max-height: 3rem;
          }

          .navbar-user {
            margin-left: calc(var(--#{$prefix}navbar-vertical-padding-x) * -1);
            margin-right: calc(var(--#{$prefix}navbar-vertical-padding-x) * -1);
            padding-top: $spacer;
            padding-bottom: calc(#{$spacer} - var(--#{$prefix}navbar-padding-y));
            padding-left: var(--#{$prefix}navbar-vertical-padding-x);
            padding-right: var(--#{$prefix}navbar-vertical-padding-x);
            border-top-width: 1px;
            border-top-style: solid;

            .dropup .dropdown-menu {
              left: 50%;
              transform: translateX(-50%);
            }
          }
        }
      }
    }
  }
}

// Vertical small
//
// Creates a small vertion of the vertical navbar

.navbar-vertical-sm {
  --#{$prefix}navbar-vertical-width: #{$navbar-vertical-width-sm};
  --#{$prefix}navbar-vertical-padding-x: #{$navbar-vertical-padding-x-sm};

  // Expanded
  //
  // Expanded navbar specific styles

  &.navbar-expand {
    @each $breakpoint, $value in $grid-breakpoints {
      &-#{$breakpoint} {
        @include media-breakpoint-up(#{$breakpoint}) {
          overflow: visible;

          .navbar-brand {
            padding-top: calc(#{($nav-link-padding-y * 2 + $font-size-base * $line-height-base) * 0.5} - var(--#{$prefix}navbar-padding-y));
            padding-bottom: ($nav-link-padding-y * 2 + $font-size-base * $line-height-base) * 0.5;
          }

          .navbar-brand-img {
            width: auto;
            max-height: 1.5rem;
          }

          .navbar-nav > .nav-item > .nav-link {
            justify-content: center;
            text-align: center;
          }

          .navbar-nav > .nav-item > .dropdown-toggle {
            &::after {
              display: none;
            }
          }

          .navbar-user .dropend .dropdown-menu {
            top: auto;
            bottom: 0;
          }
        }
      }
    }
  }
}


//
// Dark mode =======================
//

@include color-mode(dark) {
  .navbar {
    --#{$prefix}navbar-bg: #{$navbar-light-bg-dark};
    --#{$prefix}navbar-border-color: #{$navbar-light-border-color-dark};
    --#{$prefix}navbar-hover-color: #{$navbar-light-hover-color-dark};
    --#{$prefix}navbar-active-color: #{$navbar-light-active-color-dark};
    --#{$prefix}navbar-brand-color: #{$navbar-light-brand-color-dark};
    --#{$prefix}navbar-brand-hover-color: #{$navbar-light-brand-hover-color-dark};
  }
}

@include color-mode(light) {
  .navbar {
    --#{$prefix}navbar-bg: #{$navbar-light-bg};
    --#{$prefix}navbar-border-color: #{$navbar-light-border-color};
    --#{$prefix}navbar-hover-color: #{$navbar-light-hover-color};
    --#{$prefix}navbar-active-color: #{$navbar-light-active-color};
    --#{$prefix}navbar-brand-color: #{$navbar-light-brand-color};
    --#{$prefix}navbar-brand-hover-color: #{$navbar-light-brand-hover-color};

    .navbar-toggler-icon {
      --#{$prefix}navbar-toggler-icon-bg: #{escape-svg($navbar-light-toggler-icon-bg)};
    }
  }
}

@include color-mode(vibrant) {
  .navbar {
    --#{$prefix}navbar-border-color: #{$navbar-vibrant-border-color};
    --#{$prefix}navbar-color: #{$navbar-vibrant-color};
    --#{$prefix}navbar-hover-color: #{$navbar-vibrant-hover-color};
    --#{$prefix}navbar-active-color: #{$navbar-vibrant-active-color};
    --#{$prefix}navbar-heading-color: #{$navbar-vibrant-heading-color};
    --#{$prefix}navbar-divider-color: #{$navbar-vibrant-divider-color};
    --#{$prefix}navbar-brand-filter: #{$navbar-vibrant-brand-filter};
    background-image: linear-gradient(to bottom right, fade-out(#126feb, 0.1), fade-out(#7203e1, 0.1)), url(#{$path-to-img}/covers/sidebar-cover.jpg);
    background-repeat: no-repeat, no-repeat;
    background-position: center center, center center;
    background-size: cover, cover;
    background-origin: border-box;

    .navbar-toggler-icon {
      --#{$prefix}navbar-toggler-icon-bg: #{escape-svg($navbar-vibrant-toggler-icon-bg)};
    }
  
    @include media-breakpoint-down(sm) {
      background-attachment: fixed, fixed;
    }
  }
  
  .navbar.navbar-expand {
    @each $breakpoint, $value in $grid-breakpoints {
      $prev: breakpoint-prev($breakpoint, $grid-breakpoints);
  
      &-#{$breakpoint} {
        @include media-breakpoint-down(#{$prev}) {
          .navbar-nav .dropdown-item {
            --#{$prefix}dropdown-link-color: var(--#{$prefix}navbar-color);
            --#{$prefix}dropdown-link-hover-color: var(--#{$prefix}navbar-hover-color);
            --#{$prefix}dropdown-link-active-color: var(--#{$prefix}navbar-active-color);
          }
        }
      }
    }
  }
}