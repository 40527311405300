// 
// form-control.scss
// Extended from Bootstrap
//

//
// Bootstrap overrides ===================================
//

.form-control {
  --#{$prefix}input-bg: #{$input-bg};
  --#{$prefix}input-border-color: #{$input-border-color};
  --#{$prefix}input-color: #{$input-color};
  --#{$prefix}input-focus-bg: #{$input-focus-bg};
  --#{$prefix}input-focus-border-color: #{$input-focus-border-color};
  --#{$prefix}input-focus-color: #{$input-focus-color};
  --#{$prefix}input-disabled-bg: #{$input-disabled-bg};
  --#{$prefix}input-disabled-border-color: #{$input-disabled-border-color};
  --#{$prefix}input-disabled-color: #{$input-disabled-color};
  --#{$prefix}input-placeholder-color: #{$input-placeholder-color};
  background-color: var(--#{$prefix}input-bg);
  border-color: var(--#{$prefix}input-border-color);
  color: var(--#{$prefix}input-color);

  &::placeholder {
    color: var(--#{$prefix}input-placeholder-color);
  }

  &:focus {
    background-color: var(--#{$prefix}input-focus-bg);
    border-color: var(--#{$prefix}input-focus-border-color);
    color: var(--#{$prefix}input-focus-color);
  }

  &:disabled {
    background-color: var(--#{$prefix}input-disabled-bg);
    border-color: var(--#{$prefix}input-disabled-border-color);
    color: var(--#{$prefix}input-disabled-color);
  }
}

// Sizing

.form-control-sm {
  line-height: $input-line-height-sm;
}

.form-control-lg {
  line-height: $input-line-height-lg;
}


//
// Theme ===================================
//

// Form control flush
//
// Removes borders and paddings from inputs and text areas

.form-control-flush {
  padding-left: 0;
  border-color: transparent !important;
  background-color: transparent !important;
  resize: none;
}

.form-control:not(.form-select) {
  padding-right: 0;
}


// Form control auto
//
// Sets the height of the input to auto

.form-control-auto {
  min-height: 0 !important;
  padding-top: 0;
  padding-bottom: 0;
}


// Form control rounded
//
// Makes input border radius round

.form-control-rounded {
  border-radius: 20rem;
}


// Font size

.form-control {

  &.h1, &.h2, &.h3, &.h4, &.h5 {
    margin-bottom: 0;
    font-weight: $headings-font-weight;
    letter-spacing: $headings-letter-spacing;
  }

  &.h1 {
    font-size: $h1-font-size;
  }

  &.h2 {
    font-size: $h2-font-size;
  }

  &.h3 {
    font-size: $h3-font-size;
  }

  &.h4 {
    font-size: $h4-font-size;
  }

  &.h5 {
    font-size: $h5-font-size;
  }
}


//
// Theme ===================================
//

@include color-mode(dark) {
  .form-control {
    --#{$prefix}input-bg: #{$input-bg-dark};
    --#{$prefix}input-border-color: #{$input-border-color-dark};
    --#{$prefix}input-color: #{$input-color-dark};
    --#{$prefix}input-focus-bg: #{$input-focus-bg-dark};
    --#{$prefix}input-focus-color: #{$input-focus-color-dark};
    --#{$prefix}input-disabled-bg: #{$input-disabled-bg-dark};
    --#{$prefix}input-disabled-border-color: #{$input-disabled-border-color-dark};
    --#{$prefix}input-disabled-color: #{$input-disabled-color-dark};
    --#{$prefix}input-placeholder-color: #{$input-placeholder-color-dark};
  }
}

@include color-mode(light) {
  .form-control {
    --#{$prefix}input-bg: #{$input-bg};
    --#{$prefix}input-border-color: #{$input-border-color};
    --#{$prefix}input-color: #{$input-color};
    --#{$prefix}input-focus-bg: #{$input-focus-bg};
    --#{$prefix}input-focus-color: #{$input-focus-color};
    --#{$prefix}input-disabled-bg: #{$input-disabled-bg};
    --#{$prefix}input-disabled-border-color: #{$input-disabled-border-color};
    --#{$prefix}input-disabled-color: #{$input-disabled-color};
    --#{$prefix}input-placeholder-color: #{$input-placeholder-color};
  }
}