// 
// form-select.scss
// Extended from Bootstrap
//

//
// Bootstrap overrides ===================================
//

.form-select {
  --#{$prefix}form-select-bg: #{$input-bg};
  --#{$prefix}form-select-border-color: #{$input-border-color};
  --#{$prefix}form-select-color: #{$input-color};
  --#{$prefix}form-select-focus-bg: #{$input-focus-bg};
  --#{$prefix}form-select-focus-border-color: #{$input-focus-border-color};
  --#{$prefix}form-select-focus-color: #{$input-focus-color};
  --#{$prefix}form-select-disabled-bg: #{$input-disabled-bg};
  --#{$prefix}form-select-disabled-border-color: #{$input-disabled-border-color};
  --#{$prefix}form-select-disabled-color: #{$input-disabled-color};
  --#{$prefix}form-select-placeholder-color: #{$input-placeholder-color};
  background-color: var(--#{$prefix}form-select-bg);
  border-color: var(--#{$prefix}form-select-border-color);
  color: var(--#{$prefix}form-select-color);

  &::placeholder {
    color: var(--#{$prefix}form-select-placeholder-color);
  }

  &:focus {
    background-color: var(--#{$prefix}form-select-focus-bg);
    border-color: var(--#{$prefix}form-select-focus-border-color);
    color: var(--#{$prefix}form-select-focus-color);
  }

  &:disabled {
    background-color: var(--#{$prefix}form-select-disabled-bg);
    border-color: var(--#{$prefix}form-select-disabled-border-color);
    color: var(--#{$prefix}form-select-disabled-color);
  }
}


//
// Theme ===================================
//

@include color-mode(dark) {
  .form-select {
    --#{$prefix}form-select-bg-img: #{$form-select-indicator-dark};
    --#{$prefix}form-select-bg: #{$input-bg-dark};
    --#{$prefix}form-select-border-color: #{$input-border-color-dark};
    --#{$prefix}form-select-color: #{$input-color-dark};
    --#{$prefix}form-select-focus-bg: #{$input-focus-bg-dark};
    --#{$prefix}form-select-focus-color: #{$input-focus-color-dark};
    --#{$prefix}form-select-disabled-bg: #{$input-disabled-bg-dark};
    --#{$prefix}form-select-disabled-border-color: #{$input-disabled-border-color-dark};
    --#{$prefix}form-select-disabled-color: #{$input-disabled-color-dark};
    --#{$prefix}form-select-placeholder-color: #{$input-placeholder-color-dark};
  }
}