//
// flatpickr.scss
// Flatpickr plugin overrides
//

.flatpickr-calendar {
  --#{$prefix}flatpickr-bg: #{$input-bg};
  --#{$prefix}flatpickr-border-color: #{$input-border-color};
  --#{$prefix}flatpickr-color: #{$input-color};
  background-color: var(--#{$prefix}flatpickr-bg);
  border: $input-border-width solid var(--#{$prefix}flatpickr-border-color);
  color: var(--#{$prefix}flatpickr-color);
  box-shadow: none;

  * {
    color: inherit !important;
    fill: currentColor !important;
  }

  &.arrowTop:before {
    border-bottom-color: var(--#{$prefix}flatpickr-border-color);
  }

  &.arrowTop:after {
    border-bottom-color: var(--#{$prefix}flatpickr-bg);
  }

  .flatpickr-months {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
  }

  .flatpickr-prev-month,
  .flatpickr-next-month {
    top: 0.625rem;
  }

  .flatpickr-current-month {
    font-size: 115%;
  }

  .flatpickr-day {
    border-radius: var(--#{$prefix}border-radius);

    &:hover {
      background-color: var(--#{$prefix}light);
      border-color: var(--#{$prefix}flatpickr-border-color);
    }
  }

  .flatpickr-day.prevMonthDay {
    color: $body-secondary-color !important;
  }

  .flatpickr-day.today {
    border-color: var(--#{$prefix}border-color);
  }

  .flatpickr-day.selected {
    background-color: var(--#{$prefix}primary);
    border-color: var(--#{$prefix}primary);
    color: var(--#{$prefix}white) !important;
  }

  .flatpickr-day.inRange {
    background-color: var(--#{$prefix}light);
    border: none;
    border-radius: 0;
    box-shadow: -5px 0 0 var(--#{$prefix}light), 5px 0 0 var(--#{$prefix}light);
  }
}


//
// Dark mode =====================================
//

@include color-mode(dark) {
  .flatpickr-calendar {
    --#{$prefix}flatpickr-bg: #{$input-bg-dark};
    --#{$prefix}flatpickr-border-color: #{$input-border-color-dark};
    --#{$prefix}flatpickr-color: #{$input-color-dark};
  }
}