// 
// badge.scss
// Extended from Bootstrap
//

// 
// Bootstrap Overrides =====================================
//

.badge {
  vertical-align: middle;
}

// Quick fix for badges in buttons

.btn .badge {
  top: -2px;
}

// Pills

.badge.rounded-pill {
  --#{$prefix}badge-padding-x: #{$border-radius-pill-padding-x};
}
