$flash-alert: $danger;
$flash-notice: $info;
$flash-warning: $warning;

.flash {
  padding: 1.25rem;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  border: 1px solid $gray-200;
  border-left-width: .25rem;
  border-radius: $border-radius;

  h4 {
    margin-bottom: .25rem;
  }

  p:last-child {
    margin-bottom: 0;
  }

  code {
    border-radius: $border-radius;
  }

  + .flash {
    margin-top: -.25rem;
  }
}

// Variations


.flash-notice {
  border-left-color: $flash-notice;
}

.flash-alert {
  border-left-color: $flash-alert;
}

.flash-warning {
  border-left-color: $flash-warning;
}
