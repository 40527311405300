//
// checklist.scss
// Dashkit component
//

.checklist {
  --#{$prefix}checklist-control-spacer: #{$checklist-control-spacer};
  --#{$prefix}checklist-control-checked-color: #{$checklist-control-checked-color};
  outline: none;
}

.checklist .form-check {
  outline: none;
  user-select: none;
}

.checklist .form-check + .form-check {
  margin-top: var(--#{$prefix}checklist-control-spacer);
}

.checklist .form-check:first-child[style*="display: none"] + .form-check {
  margin-top: 0;
}

.checklist .form-check.draggable-mirror {
  z-index: $zindex-fixed;
}

.checklist .form-check.draggable-source--is-dragging {
  opacity: .2;
}

.checklist .form-check .form-check-input:checked + .form-check-label {
  text-decoration: line-through;
  color: var(--#{$prefix}checklist-control-checked-color);
}
