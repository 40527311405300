@import "~highcharts/css/highcharts.css";

#highcharts.chart-loaded + #highcharts-loader {
  display: none !important;
}

.highcharts-legend-item-hidden * {
  text-decoration: none;
  fill: var(--highcharts-neutral-color-40) !important;
  stroke: var(--highcharts-neutral-color-40) !important;
}

.highcharts-yaxis-grid[data-z-index="-1"] .highcharts-grid-line {
  display: none;
}
.highcharts-plot-band-label {
  font-size: 13px;
  font-weight: normal;
}
