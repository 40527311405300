// All-caps `RGBA()` function used because of this Sass bug: https://github.com/sass/node-sass/issues/2251
@each $color, $value in $theme-colors-bg-subtle {
  .text-bg-#{$color}-subtle {
    color: var(--#{$prefix}#{$color}) if($enable-important-utilities, !important, null);
    background-color: var(--#{$prefix}#{$color}-bg-subtle) if($enable-important-utilities, !important, null);
  }
}


//
// Dark mode =====================================
//

@include color-mode(dark) {
  .text-bg-light {
    color: var(--#{$prefix}white) !important;
  }
}