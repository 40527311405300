// 
// variables.scss
// Extended from Bootstrap
//

// 
// Bootstrap Overrides ===================================
//


// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.


// Color system

$white: #FFFFFF !default;
$gray-100: #F9FBFD !default;
$gray-200: #EDF2F9 !default;
$gray-300: #E3EBF6 !default;
$gray-400: #D2DDEC !default;
$gray-500: #B1C2D9 !default;
$gray-600: #95AAC9 !default;
$gray-700: #6E84A3 !default;
$gray-800: #3B506C !default;
$gray-900: #283E59 !default;
$black: #12263F !default;

$grays: (
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900
) !default;

$blue: #2C7BE5 !default;
$indigo: #727cf5 !default; // NOT USED
$purple: #6b5eae !default;
$pink: #ff679b !default; // NOT USED
$red: #E63757 !default;
$orange: #fd7e14 !default; // NOT USED
$yellow: #F6C343 !default;
$green: #00D97E !default;
$teal: #02a8b5 !default; // NOT USED
$cyan: #39afd1 !default;

$primary: $blue !default;
$secondary: $gray-700 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-200 !default;
$lighter: $gray-100 !default; // NEW
$dark: $black !default;

$primary-bg-subtle: shift-color($primary, -80%) !default;
$secondary-bg-subtle: shift-color($secondary, -80%) !default;
$success-bg-subtle: shift-color($success, -80%) !default;
$info-bg-subtle: shift-color($info, -80%) !default;
$warning-bg-subtle: shift-color($warning, -80%) !default;
$danger-bg-subtle: shift-color($danger, -80%) !default;
$light-bg-subtle: shift-color($light, -80%) !default;
$dark-bg-subtle: shift-color($dark, -80%) !default;

// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$min-contrast-ratio: 1.75 !default;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$color-contrast-dark: $gray-900 !default;
$color-contrast-light: $white !default;

// Characters which are escaped by the escape-svg function
$escaped-characters: (
  ("<","%3c"),
  (">","%3e"),
  ("#","%23"),
  ("(","%28"),
  (")","%29"),
) !default;


// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-smooth-scroll: false !default;
$enable-rfs: false !default;
$enable-validation-icons: false !default;
$enable-negative-margins: true !default;


// Prefix for :root CSS variables

$variable-prefix:             bs- !default; // Deprecated in v5.2.0 for the shorter `$prefix`
$prefix:                      $variable-prefix !default;


// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1.5rem !default;

$spacers: (
  0: 0,
  1: ($spacer * .125), // 3px
  2: ($spacer * .25), // 6px
  3: ($spacer * .5), // 12px
  4: $spacer, // 24px
  5: ($spacer * 1.5), // 36px
  6: ($spacer * 3), // 72px
  7: ($spacer * 4.5), // 108px
  8: ($spacer * 9)  // 216px
) !default;


// Body
//
// Settings for the `<body>` element.

$body-bg: $gray-100 !default;
$body-color: $black !default;

$body-secondary-color: $gray-600 !default;


// Links
//
// Style anchor elements.

$link-color: $primary !default;
$link-decoration: none !default;
$link-hover-color: darken($link-color, 15%) !default;
$link-hover-decoration: none !default;


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
) !default;


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-gutter-width: 1.5rem !default;


// Container padding

$container-padding-x: $grid-gutter-width !default;


// Components
//
// Define common padding and border radius sizes and more.

$border-width: 1px !default;
$border-color: $gray-300 !default;

$border-radius: 0.375rem !default;
$border-radius-xs: .1875rem !default;
$border-radius-sm: 0.25rem !default;
$border-radius-lg: 0.5rem !default;

$component-active-color: $white !default;
$component-active-bg: $primary !default;

$caret-width: .25em !default;


// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif: 'Cerebri Sans', sans-serif !default;
$font-family-base: var(--bs-font-sans-serif) !default;

$font-size-base: 0.9375rem !default; // 15px
$font-size-sm: 0.8125rem !default; // 13px
$font-size-lg: 1.0625rem !default; // 17px

$font-weight-light: 400 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 600 !default;

$line-height-base: 1.5 !default;
$line-height-sm: 1.75 !default;
$line-height-lg: 1.5 !default;

$h1-font-size: 1.625rem !default;
$h2-font-size: 1.25rem !default;
$h3-font-size: 1.0625rem !default;
$h4-font-size: 0.9375rem !default;
$h5-font-size: 0.8125rem !default;
$h6-font-size: 0.625rem !default;

$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size,
  base: $font-size-base,
  sm: $font-size-sm,
  lg: $font-size-lg,
) !default;

$headings-margin-bottom: $spacer * .75 !default;
$headings-font-weight: 500 !default;
$headings-line-height: 1.1 !default;

$display-font-sizes: (
  1: 4rem,
  2: 3.25rem,
  3: 2.625rem,
  4: 2rem,
) !default; 

$display-font-weight: 600 !default;
$display-line-height: $headings-line-height !default;

$lead-font-size: ($font-size-base * 1.25) !default;

$small-font-size: 0.8125rem !default;

$blockquote-font-size: ($font-size-base * 1.25) !default;

$hr-margin-y: 1rem !default;
$hr-color: var(--#{$prefix}border-color) !default;
$hr-opacity: 1 !default;

$list-inline-padding: 6px !default;


// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding-y: 1rem !default;
$table-cell-padding-x: 1rem !default;
$table-cell-padding-y-sm: 1rem !default;
$table-cell-padding-x-sm: 1rem !default;

$table-bg: transparent !default;

$table-striped-bg: var(--#{$prefix}gray-100) !default;

$table-active-bg: var(--#{$prefix}gray-100) !default;

$table-hover-bg: var(--#{$prefix}gray-100) !default;

$table-border-color: var(--#{$prefix}gray-200) !default;

$table-striped-order: even !default;

$table-group-seperator-color: var(--#{$prefix}table-border-color) !default;


// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y: .5rem !default;
$input-btn-padding-x: .75rem !default;
$input-btn-line-height: $line-height-base !default;

$input-btn-focus-width: .15rem !default;
$input-btn-focus-color: rgba($primary, .25) !default;
$input-btn-focus-box-shadow: none !default;

$input-btn-padding-y-sm: .125rem !default;
$input-btn-padding-x-sm: .5rem !default;
$input-btn-line-height-sm: $line-height-sm !default;

$input-btn-padding-y-lg: .75rem !default;
$input-btn-padding-x-lg: 1.25rem !default;
$input-btn-font-size-lg: $font-size-base !default;
$input-btn-line-height-lg: $line-height-base !default;


// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-padding-y: $input-btn-padding-y !default;
$btn-padding-x: $input-btn-padding-x !default;
$btn-line-height: $input-btn-line-height !default;

$btn-padding-y-sm: $input-btn-padding-y-sm !default;
$btn-padding-x-sm: $input-btn-padding-x-sm !default;
$btn-line-height-sm: $input-btn-line-height-sm !default;

$btn-padding-y-lg: $input-btn-padding-y-lg !default;
$btn-padding-x-lg: $input-btn-padding-x-lg !default;
$btn-line-height-lg: $input-btn-line-height-lg !default;

$btn-font-weight: $font-weight-normal !default;
$btn-focus-box-shadow: $input-btn-focus-box-shadow !default;

$btn-link-disabled-color: var(--#{$prefix}gray-500) !default;


// Forms

$form-text-margin-top: 0 !default;

$input-padding-y: $input-btn-padding-y !default;
$input-padding-x: $input-btn-padding-x !default;
$input-line-height: $input-btn-line-height !default;

$input-padding-y-sm: $input-btn-padding-y-sm !default;
$input-padding-x-sm: $input-btn-padding-x-sm !default;
$input-line-height-sm: $input-btn-line-height-sm !default;

$input-padding-y-lg: $input-btn-padding-y-lg !default;
$input-padding-x-lg: $input-btn-padding-x-lg !default;
$input-line-height-lg: $input-btn-line-height-lg !default;

$input-bg: $white !default;
$input-disabled-bg: $input-bg !default;

$input-color: var(--#{$prefix}body-color) !default;
$input-border-color: var(--#{$prefix}gray-400) !default;

$input-focus-border-color: var(--#{$prefix}primary) !default;
$input-focus-box-shadow: $input-btn-focus-box-shadow !default;

$input-placeholder-color: var(--#{$prefix}gray-500) !default;

$input-height-inner-sm: ($font-size-sm * $input-btn-line-height-sm) + ($input-btn-padding-y-sm * 2) !default;

$form-check-input-active-filter: none !default;

$form-check-padding-start: 1.5rem !default;
$form-check-label-cursor: pointer !default;

$form-check-input-bg: var(--#{$prefix}gray-300) !default;
$form-check-input-border: transparent !default;
$form-check-input-border-radius: var(--#{$prefix}border-radius) !default;
$form-check-input-focus-border: $form-check-input-border !default;
$form-check-input-focus-box-shadow: none !default;

$form-check-input-checked-color: $component-active-color !default;
$form-check-input-checked-bg-color: $component-active-bg !default;
$form-check-input-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-checked-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/></svg>") !default;
$form-check-radio-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#{$form-check-input-checked-color}'/></svg>") !default;

$form-check-input-indeterminate-color: $component-active-color !default;

$form-switch-color: $white !default;
$form-switch-width: 3rem !default;
$form-switch-padding-start: $form-switch-width + .5rem !default;
$form-switch-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-color}'/></svg>") !default;

$form-switch-focus-color: $form-switch-color !default;
$form-switch-focus-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-focus-color}'/></svg>") !default;

$form-switch-checked-color: $form-switch-color !default;
$form-switch-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-checked-color}'/></svg>") !default;

$input-group-addon-color: var(--#{$prefix}secondary-color) !default;
$input-group-addon-bg: $input-bg !default;

$form-select-indicator-padding: 1.75rem !default;
$form-select-bg-size: .75em .75em !default;
$form-select-indicator-color: $gray-600 !default;
$form-select-indicator: url("data:image/svg+xml,<svg viewBox='0 0 9 5' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M1 1L4.25 4.25L7.5 1' stroke='#{$form-select-indicator-color}' stroke-width='1.08333' stroke-linecap='round' stroke-linejoin='round'/></svg>") !default;

$form-select-focus-border-color: $input-focus-border-color !default;
$form-select-focus-box-shadow: $input-focus-box-shadow !default;


// Navs

$nav-link-focus-box-shadow: 0 0 !default;

$nav-tabs-border-color: var(--#{$prefix}border-color) !default;
$nav-tabs-border-radius: 0 !default;
$nav-tabs-link-hover-border-color: transparent transparent transparent !default;
$nav-tabs-link-active-color: var(--#{$prefix}body-color) !default;
$nav-tabs-link-active-bg: transparent !default;
$nav-tabs-link-active-border-color: transparent transparent var(--#{$prefix}primary) !default;

$nav-pills-link-active-bg: $component-active-bg !default;


// Navbar

$navbar-padding-x: 1rem !default;

$navbar-brand-font-size: $font-size-lg !default;
$navbar-brand-padding-y: 0 !default;

$navbar-toggler-padding-x: 0 !default;
$navbar-toggler-focus-width: 0 !default;

$navbar-light-color: var(--#{$prefix}gray-700) !default;
$navbar-light-hover-color: var(--#{$prefix}black) !default;
$navbar-light-active-color: var(--#{$prefix}black) !default;
$navbar-light-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$gray-700}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
$navbar-light-toggler-border-color: transparent !default;


// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-spacer: 0 !default;
$dropdown-border-color: rgba(var(--#{$prefix}black-rgb), .1) !default;
$dropdown-bg: var(--#{$prefix}white) !default;

$dropdown-link-color: var(--#{$prefix}gray-700) !default;
$dropdown-link-hover-color: var(--#{$prefix}black) !default;
$dropdown-link-hover-bg: transparent !default;

$dropdown-link-active-color: var(--#{$prefix}dropdown-link-hover-color) !default;
$dropdown-link-active-bg: var(--#{$prefix}dropdown-link-hover-bg) !default;

$dropdown-item-padding-y: .375rem !default;

$dropdown-header-color: inherit !default;


// Pagination

$pagination-padding-y: $input-btn-padding-y !default;
$pagination-padding-x: $input-btn-padding-x !default;
$pagination-padding-y-sm: $input-btn-padding-y-sm !default;
$pagination-padding-x-sm: $input-btn-padding-x-sm !default;
$pagination-padding-y-lg: $input-btn-padding-y-lg !default;
$pagination-padding-x-lg: $input-btn-padding-x-lg !default;

$pagination-color: var(--#{$prefix}black) !default;
$pagination-bg: var(--#{$prefix}white) !default;

$pagination-focus-box-shadow: none !default;

$pagination-hover-color: var(--#{$prefix}dark) !default;
$pagination-hover-bg: var(--#{$prefix}gray-100) !default;
$pagination-hover-border-color: var(--#{$prefix}gray-300) !default;

$pagination-active-bg: $component-active-bg !default;
$pagination-active-border-color: var(--#{$prefix}pagination-active-bg) !default;


// Cards

$card-spacer-y: $spacer !default;
$card-spacer-x: $spacer !default;
$card-title-spacer-y: .5rem !default;
$card-border-width: var(--#{$prefix}border-width) !default;
$card-border-color: var(--#{$prefix}gray-200) !default;
$card-border-radius: var(--#{$prefix}border-radius-lg) !default;
$card-box-shadow: 0 .75rem 1.5rem rgba(var(--#{$prefix}black-rgb), .03) !default;
$card-inner-border-radius: calc(#{var(--#{$prefix}card-border-radius)} - #{var(--#{$prefix}card-border-width)}) !default;
$card-cap-padding-y: 1rem !default;
$card-cap-padding-x: var(--#{$prefix}card-spacer-x) !default;
$card-cap-bg: transparent !default;
$card-bg: $white !default;


// Accordion

$accordion-bg: $card-bg !default;

$accordion-body-padding-y: $card-spacer-y !default;
$accordion-body-padding-x: $card-spacer-x !default;

$accordion-button-padding-y: $card-cap-padding-y !default;
$accordion-button-padding-x: $card-spacer-x !default;
$accordion-button-active-bg: transparent !default;
$accordion-button-active-color: var(--#{$prefix}body-color) !default;

$accordion-icon-width: .75rem !default;


// Tooltips

$tooltip-color: var(--#{$prefix}black) !default;
$tooltip-bg: var(--#{$prefix}gray-300) !default;
$tooltip-opacity: 1 !default;

$tooltip-arrow-color: var(--#{$prefix}tooltip-bg) !default;


// Popovers

$popover-bg: var(--#{$prefix}white) !default;
$popover-max-width: 10rem !default;
$popover-box-shadow: 0 0 1rem transparentize($black, .97) !default;

$popover-header-bg: var(--#{$prefix}popover-bg) !default;
$popover-header-padding-y: 0 !default;
$popover-header-padding-x: 0 !default;

$popover-body-padding-y: 0 !default;
$popover-body-padding-x: 0 !default;

$popover-body-color: $body-secondary-color !default;


// Toasts

$toast-max-width: 300px !default;
$toast-padding-x: 1rem !default;
$toast-padding-y: 0.75rem !default;
$toast-font-size: $font-size-base !default;
$toast-background-color: var(--#{$prefix}white) !default;
$toast-border-color: var(--#{$prefix}border-color) !default;
$toast-border-radius: var(--#{$prefix}border-radius) !default;
$toast-box-shadow: 0 0.75rem 1.5rem fade-out($black, .9) !default;

$toast-header-color: var(--#{$prefix}body-color) !default;
$toast-header-background-color: $toast-background-color !default;
$toast-header-border-color: var(--#{$prefix}border-color) !default;


// Badges

$badge-font-size: 76% !default;
$badge-font-weight: $font-weight-normal !default;
$badge-padding-y: .33em !default;
$badge-padding-x: .5em !default;


// Modals

$modal-inner-padding: 1.5rem !default;

$modal-content-border-color: transparent !default;

$modal-header-border-color: var(--#{$prefix}border-color) !default;
$modal-footer-border-color: var(--#{$prefix}border-color) !default;
$modal-header-padding-y: 1rem !default;
$modal-header-padding: var(--#{$prefix}modal-header-padding-y) $modal-inner-padding !default;

$modal-md: 600px !default;


// Alerts
//
// Define alert colors, border radius, and padding.

$alert-padding-y: .75rem !default;
$alert-padding-x: 1.25rem !default;
$alert-margin-bottom: $spacer !default;
$alert-link-font-weight: $font-weight-normal !default;


// Progress bars

$progress-border-radius: 200px !default; // Always rounds even if height is manually set higher


// List groups

$list-group-bg: transparent !default;
$list-group-border-color: var(--#{$prefix}gray-200) !default;

$list-group-item-padding-y: 1rem !default;
$list-group-item-padding-x: 1.25rem !default;

$list-group-action-color: var(--#{$prefix}body-color) !default;


// Figures

$figure-caption-font-size: $small-font-size !default;


// Breadcrumbs

$breadcrumb-padding-x: 0 !default;
$breadcrumb-item-padding-x: .35rem !default;
$breadcrumb-margin-bottom: 0 !default;
$breadcrumb-bg: transparent !default;
$breadcrumb-divider-color: var(--#{$prefix}gray-400) !default;
$breadcrumb-active-color: var(--#{$prefix}gray-600) !default;
$breadcrumb-divider: "" !default;


// Close

$btn-close-width: .5em !default;
$btn-close-padding-x: .5em !default;


// Offcanvas

$offcanvas-horizontal-width: 350px !default;
$offcanvas-padding-x: $modal-inner-padding !default;


// Code

$code-font-size: 87.5% !default;
$code-color: $primary !default;


// 
// Theme =====================================
//


// Components
//
// Define common padding and border radius sizes and more.

$box-shadow-lift: 0 1rem 2.5rem fade-out($black, .9), 0 .5rem 1rem -.75rem fade-out($black, .9) !default;
$box-shadow-lift-lg: 0 2rem 5rem fade-out($black, .9), 0 .5rem 1rem -.75rem fade-out($black, .95) !default;


// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-size-xs: ($font-size-base * .6666666667) !default; // 10px

$headings-letter-spacing: -.02em !default;

$display-letter-spacing: -.02em !default;


// Tables

$table-head-bg: var(--#{$prefix}gray-100) !default;
$table-head-color: var(--#{$prefix}secondary-color) !default;


// Buttons

$btn-text-color: var(--#{$prefix}white) !default;


// Forms

$form-text-margin-bottom: .5rem !default;

$form-check-size: 1rem !default; // using this instead of $form-check-input-width because of the units incompatibility bug
$form-check-input-focus-bg: lighten($primary, 35%) !default;
$form-check-input-checked-bg-size: 75% 75% !default;

$form-switch-min-height: 1.5rem !default;

$form-check-circle-indicator-size: 1.375rem !default;
$form-check-circle-indicator-border-radius: 50% !default;
$form-check-circle-indicator-icon-checked: url(escape-svg("data:image/svg+xml,<svg viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M11.3333 1L3.99996 8.33333L0.666626 5' stroke='#{$white}' stroke-width='1.33333' stroke-linecap='round' stroke-linejoin='round'/></svg>")) !default;
$form-check-circle-indicator-bg-size: .75rem .5625rem !default;
$form-check-circle-padding-start: 1.875rem !default;

$form-group-margin-bottom: 1.375rem !default;


// Navs

$nav-link-padding-y: .5rem !default;
$nav-link-padding-x: 1rem !default;
$nav-link-font-size-sm: $font-size-sm !default;

$nav-tabs-link-active-border-width: 1px !default;
$nav-tabs-link-margin-x: .75rem !default; // Margin used for X so active underline matches width of label
$nav-tabs-link-padding-y: $card-cap-padding-y !default; // This keeps the height the same as the card header
$nav-tabs-link-margin-x-sm: .5rem !default;
$nav-tabs-link-color: var(--#{$prefix}gray-600) !default;
$nav-tabs-link-hover-color: var(--#{$prefix}gray-700) !default;

$nav-tabs-font-size-sm: $nav-link-font-size-sm !default;


// Navbar

$navbar-vertical-width: 250px !default;
$navbar-vertical-width-sm: 66px !default;
$navbar-vertical-expand-breakpoint: 'md' !default; // Doesn't affect the actual breakpoint at which the navbar expands. Used for positioning of other elements only (e.g. `.select-alert`)

$navbar-vertical-padding-x: 1.5rem !default;
$navbar-vertical-padding-x-sm: 0.75rem !default;

$navbar-icon-min-width: 1.75rem !default;

$navbar-breadcrumb-padding-y: $nav-link-padding-y !default;
$navbar-breadcrumb-padding-x: 0 !default;

$navbar-vibrant-border-color: transparent !default;
$navbar-vibrant-heading-color: fade-out($white, .6) !default;
$navbar-vibrant-divider-color: fade-out($white, .8) !default;
$navbar-vibrant-color: fade-out($white, .3) !default;
$navbar-vibrant-hover-color: var(--#{$prefix}white) !default;
$navbar-vibrant-active-color: var(--#{$prefix}white) !default;
$navbar-vibrant-brand-filter: brightness(0) invert(1) !default;
$navbar-vibrant-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-vibrant-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;

$navbar-light-bg: var(--#{$prefix}white) !default;
$navbar-light-border-color: var(--#{$prefix}border-color) !default;
$navbar-light-heading-color: var(--#{$prefix}secondary-color) !default;
$navbar-light-divider-color: var(--#{$prefix}border-color) !default;
$navbar-light-brand-filter: none;


// Dropdowns

$dropdown-card-min-width: 350px !default;
$dropdown-card-header-min-height: 3.125rem !default;
$dropdown-card-body-max-height: 350px !default;

$dropdown-padding-y-sm: .25rem !default;
$dropdown-font-size-sm: $font-size-sm !default;
$dropdown-item-padding-y-sm: .1875rem !default;
$dropdown-item-padding-x-sm: .75rem !default;


// Pagination

$pagination-line-height: 1.25 !default;
$pagination-line-height-lg: $line-height-base !default;
$pagination-line-height-sm: $line-height-sm !default;

$pagination-font-size: $font-size-base !default;
$pagination-font-size-lg: $font-size-base !default;
$pagination-font-size-sm: $font-size-sm !default;

$pagination-tabs-padding-y: 1.25rem !default;
$pagination-tabs-padding-x: .75rem !default;

$pagination-tabs-bg: transparent !default;
$pagination-tabs-border-color: var(--#{$prefix}border-color) !default;
$pagination-tabs-color: $body-secondary-color !default;

$pagination-tabs-hover-bg: $pagination-tabs-bg !default;
$pagination-tabs-hover-color: var(--#{$prefix}body-color) !default;

$pagination-tabs-focus-bg: $pagination-tabs-hover-bg !default;
$pagination-tabs-focus-color: $pagination-tabs-hover-color !default;

$pagination-tabs-active-bg: $pagination-tabs-bg !default;
$pagination-tabs-active-border-color: var(--#{$prefix}primary) !default;
$pagination-tabs-active-color: var(--#{$prefix}body-color) !default;


// Cards

$card-margin-bottom: $spacer !default;
$card-spacer-x-sm: 1rem !default;

$card-outline-color: $card-border-color !default;

$card-header-height: 60px !default;


// Popovers

$popover-padding-x: .95rem !default;
$popover-padding-y: .8rem !default;

$popover-max-width-lg: 200px !default;

$popover-header-margin-bottom: .25rem !default;


// List groups

$list-group-item-padding-y-lg: $spacer !default;


// Badges

$border-radius-pill-padding-x: .6em !default;


// Modal

$modal-card-body-max-height: 350px !default;


// Alerts
//
// Define alert colors, border radius, and padding.

$alert-link-text-decoration: underline !default;


// Breadcrumbs

$breadcrumb-padding-y-sm: .5rem !default;
$breadcrumb-padding-x-sm: .5rem !default;
$breadcrumb-item-padding-sm: .25rem !default;

$breadcrumb-margin-bottom-sm: .25rem !default;
$breadcrumb-font-size-sm: $font-size-sm !default;


// Progress bars

$progress-height-sm: 0.25rem !default;


// Offcanvas

$offcanvas-header-padding-y: 1rem !default;
$offcanvas-header-padding-x: var(--#{$prefix}offcanvas-padding-x) !default;


// Auth

$auth-bg: $white !default;


// Avatar

$avatar-size-base: 3rem !default;
$avatar-size-xs: 1.625rem !default;
$avatar-size-sm: 2.5rem !default;
$avatar-size-lg: 4rem !default;
$avatar-size-xl: 5.125rem !default;
$avatar-size-xxl: 8rem !default;

$avatar-title-bg: var(--#{$prefix}gray-500) !default;
$avatar-title-color: var(--#{$prefix}white) !default;


// Charts

$chart-height: 300px !default;
$chart-height-sm: 225px !default;

$chart-sparkline-width: 75px !default;
$chart-sparkline-height: 35px !default;

$chart-legend-margin-top: 2.5rem !default;
$chart-legend-font-size: $font-size-sm !default;
$chart-legend-color: $body-secondary-color !default;
$chart-legend-height: $chart-legend-margin-top + $chart-legend-font-size * $line-height-base !default;

/* beautify ignore:start */
$chart-colors: (
  "gray-300": $gray-300,
  "gray-600": $gray-600,
  "gray-700": $gray-700,
  "gray-800": $gray-800,
  "gray-900": $gray-900,
  "primary-100": "#D2DDEC",
  "primary-300": "#A6C5F7",
  "primary-700": $primary,
  "default-color": $gray-600,
  "default-font-color": $gray-600,
  "arc-border-color": $white,
  "arc-hover-border-color": $white,
  "grid-line-color": $gray-300,
) !default;
/* beautify ignore:end */


// Checklist

$checklist-control-spacer: .75rem !default;
$checklist-control-checked-color: var(--#{$prefix}gray-700) !default;


// Comment

$comment-margin-bottom: 1rem !default;

$comment-body-padding-y: 1rem !default;
$comment-body-padding-x: 1.25rem !default;
$comment-body-bg: var(--#{$prefix}body-bg) !default;
$comment-body-border-radius: var(--#{$prefix}border-radius-lg) !default;
$comment-body-font-size: $font-size-sm !default;

$comment-time-margin-bottom: .5625rem !default;
$comment-time-font-size: $font-size-xs !default;
$comment-time-color: var(--#{$prefix}secondary-color) !default;


// Header

$header-margin-bottom: 2rem !default;
$header-spacing-y: 1.5rem !default;
$header-bg: null !default;
$header-color: null !default;

$header-body-border-width: 1px !default;
$header-body-border-color: var(--#{$prefix}border-color) !default;

$header-tabs-link-color: $nav-tabs-link-color !default;
$header-tabs-link-hover-color: $nav-tabs-link-hover-color !default;


// Kanban

$kanban-col-width: 375px !default;


// List

$list-alert-min-width: 350px !default;


// Main content

$main-content-padding-y: 2.5rem !default;
$main-content-padding-x: 1.5rem !default;


// Paths

$path-to-fonts: '../fonts' !default;
$path-to-img: '../img' !default;
